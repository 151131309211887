
import { Component, Prop, Vue } from "vue-property-decorator";
import { assetsUrl } from "@/env";

@Component({ name: "ScorePin" })
export default class ScorePin extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  isGroup: boolean | undefined;
  @Prop({ required: false, type: Boolean, default: false })
  isExample: boolean | undefined;
  @Prop({ required: true, type: Number })
  scorePercentage: number | undefined;
  @Prop({ required: false, type: Number })
  score: number | undefined;
  @Prop({ required: false, type: Boolean })
  displayIndivScore: boolean | undefined;
  @Prop({ required: false, type: Boolean })
  displayGroupScore: boolean | undefined;
  @Prop({ required: true, type: Number })
  scoreNumber!: number;
  @Prop({ required: false, type: Number, default: 0 })
  sameScore: number | undefined;

  getPinStyle(scorePercentage: number): string {
    return `left: calc(${scorePercentage}% - ${
      this.isExample ? "12px" : this.isGroup ? "22px" : "12px"
    }); ${
      this.sameScore && this.sameScore > 1
        ? "position: absolute; bottom: " +
          (this.sameScore > 2
            ? 45 + (this.sameScore - 2) * 20
            : (this.sameScore - 1) * 45) +
          "px"
        : ""
    }`;
  }

  getPinClassName(): string {
    return `score-pin ${this.isGroup ? "group-pin" : "single-pin"} ${
      this.sameScore && this.sameScore > 1 ? "hide-after" : ""
    }`;
  }

  getGroupImgSrc(): string {
    return `${assetsUrl}/svg/${this.scoreNumber}_users.svg`;
  }
}
