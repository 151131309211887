
import { Component, Prop, Vue } from "vue-property-decorator";
import ScorePin from "@/components/feedback/components/ScorePin.vue";
@Component({
  components: { ScorePin },
})
export default class Score extends Vue {
  @Prop({ required: false, type: Number, default: 0 })
  min: number;

  @Prop({ required: false, type: Boolean, default: false })
  isExample: boolean;

  @Prop({ required: false, type: Number, default:112})
  scoreMax: number | undefined;
  @Prop({ required: false, type: Number, default: null })
  groupScore: number | undefined;
  @Prop({ required: false, type: Array })
  indivScores:
    | {
        user: string;
        score: number;
        sameScore?: number;
      }[]
    | undefined;
  @Prop({ required: false, type: Boolean, default: false })
  displayIndivScore: boolean | undefined;
  @Prop({ required: false, type: Boolean, default: false })
  displayGroupScore: boolean | undefined;
  @Prop({ required: false, type: Boolean, default: true })
  displayGroupPin: boolean | undefined;

  data(): any {
    return {
     steps: [],
     max :112,
    }
  }

  created(): void {
    let checked = [];
    this.steps= [
      {
        interval: {
          min: 0,
          max: (this.scoreMax/7),
        },
        // color: "red",
        color: "green lighten-5",
      },
      {
        interval: {
          min: (this.scoreMax/7),
          max: (2*this.scoreMax/7),
        },
        color: "green lighten-4",
        // color: "deep-orange",
      },
      {
        interval: {
          min: (2*this.scoreMax/7),
          max: (3*this.scoreMax/7),
        },
        color: "green lighten-3",
        // color: "orange",
      },
      {
        interval: {
          min: (3*this.scoreMax/7),
          max: (4*this.scoreMax/7),
        },
        // color: "amber",
        color: "green lighten-2",
      },
      {
        interval: {
          min: (4*this.scoreMax/7),
          max: (5*this.scoreMax/7),
        },
        // color: "yellow",
        color: "green lighten-1",
      },
      {
        interval: {
          min: (5*this.scoreMax/7),
          max: (6*this.scoreMax/7),
        },
        // color: "lime",
        color: "green darken-2",
      },
      {
        interval: {
          min: (6*this.scoreMax/7),
          max: this.scoreMax,
        },
        color: "green darken-4",
        // color: "green",
      },
    ]
    this.indivScores?.forEach((score, index, scores) => {
      let occurence = score.score;
      this.max=this.scoreMax
      if (occurence == -1){
        occurence =-1000
      }
      if (checked.includes(occurence)) return;
      let occurences = scores.filter((s) => s.score === occurence);
      occurences.forEach((occ, index) => {
        this.indivScores[
          this.indivScores?.findIndex((s) => s.user === occ.user)
        ].sameScore = index + 1;
      });
      checked.push(occurence);
    });
  }

  /**
   * If steps are equal
   * Return percentage of the maximum
   */
  get stepsInterval(): number {
    return this.steps?.length ? (this.max - this.min) / this.steps.length : 100;
  }

  /**
   * Return number of steps
   */
  get stepsNumber(): number {
    return this.steps?.length ? this.steps.length : 1;
  }

  /**
   * Return proportional percentage from interval
   * @param value
   */
  getPercentage(value: number): number {
    return (value * 100) / this.max;
  }

  /**
   * Return interval value from percentage
   * @param percentage
   */
  getValueFromPercentage(percentage: number): number {
    return (percentage * this.max) / 100;
  }

  /**
   * Return css style for step
   * @param step
   */
  getStepStyle(step: Record<string, any>): string {
    return `left: ${this.getPercentage(step.interval.min)}%;
          width: ${this.getPercentage(
            step.interval.max - step.interval.min
          )}%;`;
    // Uncomment if wants to display limits
    // display: flex;
    // flex-direction: row;
    // padding-right: 10px;
    // padding-left: 10px;`;
  }
}
